import React from "react";

const Footer = () => {
  return (
    <footer className="footerHome container-fluid py-md-4 py-3" >
      <div className="container">
        <div class="row">
         
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="footer-content ">
              <div class="footer-head">
                <h4>Usefull Links</h4>
                <ul class="footer-list">
                  <li><a className="anker" href="/">Home</a></li>
                  <li><a className="anker" href="#about">About</a></li>
                  <li><a className="anker" href="#trade">Why Trade </a></li>
                  <li><a className="anker" href="#coin">Coin Market </a></li>
                  <li><a className="anker" href="#faq">Faq</a></li>
                  <li><a className="anker" href="#contact">Contact</a></li>
                  <li><a className="anker" href="/login">Login</a></li>
                  <li><a className="anker" href="/Register">Register</a></li>
                  {/* <li><a className="anker" href="#">Privacy Policy</a></li>
                  <li><a className="anker" href="#">Terms &amp; Conditions</a></li> */}
                </ul>
                
              </div>
            </div>
          </div>
         
        </div>
       
      </div>
      <div className="row pt-4 pb-2 border-top">
          <div className="col-12">
            <p className="text-white text-center">Copyright © 2024BigBet All Rights Reserved</p>
          </div>
        </div>
    </footer>

  );
};

export default Footer;
