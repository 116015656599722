import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
const Reward = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [id, setid] = useState(0);

  const Direct_API = async () => {
    try {
      let responce = await API?.post(
        `/RewardIncome`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("RewardIncome-->", responce);
      responce = responce?.data?.data[0];
      let arr = [];

      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Rowid: item?.id,
          User_Id: item?.uid,
          lvl: item?.lvl,
          lbv: item?.lbv,
          rbv: item?.rbv,
          Rankname:item?.Rankname,
          mbvnew: (<>
            <p1
              className="text-success"
            >
              {item?.mbvnew}
            </p1>
          </>),
          rmbv: (<>
            <p1
              className="text-warning"
            >
              {item?.rmbv}
            </p1>
          </>),
          gift: item?.Gift1,
          RemainBusiness: item?.RemainBusiness,
          amount: item?.Ramount,
          achieved_dd: item?.achieved_dd,
          remark: item?.remark == "Completed" ? (<>
            <p1
              className="text-success"
            >
              {item?.remark}
            </p1>
          </>) :
            (<p1
              className="text-danger"
            >
              {item?.remark}
            </p1>),

          ClaimStatus:
            item?.ClaimStatus[0] === "Successfully Claimed" ?
              (<>
                <p1
                  className="text-success"
                >
                  {item?.ClaimStatus}
                </p1>
              </>) :
              item?.ClaimStatus[0] === "Pending" ?
                (<>
                  <p1
                    className="text-danger"
                  >
                    {item?.ClaimStatus}
                  </p1>
                </>) :
                item?.ClaimStatus[0] === "Claim Now" ?
                  (
                    <>
                      <button
                        className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                        onClick={() => Claim_Post_API(item?.id, item?.uid)}
                      >
                        Claim Now
                      </button>
                    </>
                  ) : (
                    item?.ClaimStatus[0]
                  ),
          claimed_dd: item?.claimed_dd,
          //achieved:  item?.achieved === 1 ? 'Qualify' : 'Pending'
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Claim_Post_API = async (RowId, userId) => {
    try {
      alert(RowId);
      alert(userId);
      let responce = await API?.post(
        `/RewardClaim_By_User`,
        {
          uid: userId,
          id: RowId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("Claim_Post_API", responce);
      responce = responce?.data?.data;
      toast.success(responce);

      setid("");
      Direct_API();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Direct_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  console.log("currentPost", currentPost);
  // setcurrentPost(referralApi.slice(indexOfFirstPage, indexOfLastPost));

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      // { Header: "User ID", accessor: "User_Id" },
      // { Header: "Level", accessor: "lvl" },
      { Header: "Gift", accessor: "gift" },
      { Header: "Rank", accessor: "Rankname" },
      { Header: "Target Matching", accessor: "rmbv" },
      // { Header: "Left Business", accessor: "lbv" },
      // { Header: "Right Business", accessor: "rbv" },
      { Header: "Matched Business", accessor: "mbvnew" },
      { Header: "Remaning Business", accessor: "RemainBusiness" },
      // { Header: "Amount", accessor: "amount" },
      { Header: "Achieve Status", accessor: "remark" },
      { Header: "Achieved Date", accessor: "achieved_dd" },
      { Header: "Claim Status", accessor: "ClaimStatus" },
      { Header: "Claim Date", accessor: "claimed_dd" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Reward</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Report</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Reward
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja" style={{ overflow: "scroll" }}>
              <Table data={[...referralApi]} columns={matching_income.cols} />
              {/* <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Reward;
