import React from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";

const Buy_Token = () => {
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Buy Token</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Buy</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Buy Token
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100 flex-wrap ">
                    <h4 className="w-100 text-center pt-2">
                      Buy Wallet Balance : 0
                    </h4>
                    <p className="text-white fs-16 text-center w-100">
                      Buy Token Rate : 0.02439388
                    </p>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput"
                              readOnly=""
                              defaultValue={0}
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-7 mt-3 text-end">
                            <div className="btn btn-Green py-2 px-3 paymentadd mb-2">
                              $ 20
                            </div>
                          </div>
                          <div className="col-5 text-start py-3">
                            <button className="btn btn-Green py-2 px-5 restart">
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-6 row align-items-center justify-content-center">
                            <button className="btn btn_hxn withdraw_btn w-100 py-3 shadow-none">
                              Buy Token
                            </button>
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0">
                              Buy Token Amount is $20.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Buy_Token;
