import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";

const Direct_Earning_Income_release = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const location = useLocation();
  const rowId = location.state?.rowId; 

  const Direct_API = async () => {
    try {
      let responce = await API?.post(
        `/DirectIncome_Release`,
        {
          uid: userId,
          rowid: userId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("activationHistory", responce);
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          User_Id: item?.uid,
          fromid: item?.fromid,
          wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
            item.wallet.length - 4
          )}`,
          Date: item?.dd,
          Remark: ` ${item?.remark}`,
          Amount: `$ ${item?.onamount}`,
          income: `$ ${item?.income}`,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Direct_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  console.log("currentPost", currentPost);
  // setcurrentPost(referralApi.slice(indexOfFirstPage, indexOfLastPost));

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "User ID", accessor: "User_Id" },
      { Header: "From Id", accessor: "fromid" },
      { Header: "From Wallet", accessor: "wallet" },
      { Header: "Income", accessor: "income" },
      // { Header: "On Amount", accessor: "Amount" },
      { Header: " Date", accessor: "Date" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Daily Direct Income</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Report</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                         Daily Direct Income
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Direct_Earning_Income_release;
