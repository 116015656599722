import React from "react";
import DashHeader from "../DashHeader/DashHeader";
import "./Adjustment.css";

const Adjustment = () => {
  return (
    <div>
        <DashHeader />
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row">
            <div className="col-12">
              <div className="heading text-white fw-bold fs-18 mb-3">
                ADJUSTMENT
              </div>
            </div>
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body p-0 p-sm-2">
                  <div className="row mx-0 pb-0 gy-3 align-items-center">
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Total Investment
                          </div>
                          <div className="text-white subtext">0</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Adjustment
                          </div>
                          <div className="text-white subtext">0</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Remaining
                          </div>
                          <div className="text-white subtext">0</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adjustment;
