import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eaglebetAuth: false,
  userId: null,
  jwtToken: null,
  ipAddress: null,
  status:'In-Active'
};

export const AuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.eaglebetAuth = action.payload.isAuth;
      state.userId = action.payload.userId;
      state.jwtToken = action.payload.jwtToken;
      state.ipAddress = action.payload.ipAddress;
    },
    updateStatus:(state,action)=>{
        state.status=action.payload.status
    }
  },
});

export const { updateAuth, updateStatus} = AuthSlice.actions;
export default AuthSlice.reducer;
