import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Roadmap from "./Roadmap";
import Popup from "./Popup";

const Hero = () => {
  return (
    <div>
      {/* <div className="sidenav">
        <a href="https://t.me/+eK1k5qZb3ThiYjk0" target="_blank">
          <img
            src="./assets/image/home/telegram.png"
            class="w-30"
            alt="Telegram"
          />
        </a>
      </div> */}
      {/* <Popup /> */}
      <Header />
      <div className="container-fluid herosectioin">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-6 d-flex align-items-staret justify-content-center flex-column">
              <h1 className="Heroheading">Welcome to the most Sustainable and <span>Profitable </span>Gaming Platform</h1>
             <div className="d-flex">
              <a href="/login" className="btn HeroBtn">Sign In</a>&nbsp; &nbsp;
              <a href="/Register" className="btn HeroBtn">Sign Up</a>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-staret justify-content-center flex-column">
              <div className="justify-content-center flex-column ">
                <center>
                <img src="./assets/image/img.png"/>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>

      <div className="nftsection container-fluid py-md-4 py-3" id="about">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-md-6">
              <div className="nftimage">
                <img className="w-100 h-100" src="./assets/image/avi.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="nftheading">About <span> Aviator</span></div>
              <p>
              Skyrocket engagement, multiply the thrill! Introducing Aviator, a fresh spin on one the most popular and most played games — Crash! Aviator is a captivating and exciting game that comes with impeccable graphics and immersive gameplay and offers unlimited thrill to the players. Aviator comes with features that include Auto bet and two bets that ensure maximum player engagement. 

              </p>
            </div>
          
          </div>
        </div>
      </div>
      <div className="container-fluid swapsection py-md-4 py-3" id="trade">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="swapHeading">Why Trade With</div>
              <div className="contentbox">
                <div className="contentheading">Trade Genius</div>
                <p>Trading is the art and science of buying and selling financial instruments, such as stocks bonds currencies.</p>
                <a className="anker" href="">Learn More  ➔</a>
              </div>
              <div className="contentbox">
                <div className="contentheading">Trade Apex</div>
                <p>Trading is the art and science of buying and selling financial instruments, suc stocks, bonds, currencies commodities, and cryptocurrencies, with the aim of making a profit. It's a dynamic and multifaceted professionals from around the world</p>
                <a className="anker" href="">Learn More  ➔</a>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="swapimage">
                <img className="w-100 h-100" src="./assets/image/NewHome/swapimage.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nftsection container-fluid py-md-4 py-3">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-md-6">
              <div className="nftimage">
                <img className="w-100 h-100" src="./assets/image/NewHome/nft.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="nftheading">How to Create Big<span> Bet Marketplace</span></div>
              <p>Big Bet allow artist to establish ownership of their digital creations and ensure that they are not duplicated or passed off as someone else's work.In addition to establishing authenticity, NFTs also provide a way to trace the ownership history of a particular digital asset.It's rather rare to sell an NFT for millions. But they do offer artists the chance to sell art online and make it possible for collectors, investors and auctioneers to make a profit.</p>
            </div>
          
          </div>
        </div>
      </div>
      <div className="tablesection container-fluid py-md-4 py-3 d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">Low spreads on more than 150 instruments</div>
            </div>
            <div className="col-12">
              
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid coinmarketsection py-md-4 py-3 " id="coin">
          <div className="container">
          <div className="row">
            <div className="col-12 text-center">
            <div className="heading mb-4">Coin Market Cap</div>
            </div>
            <div className="col-12">
              <div className="coinimage">
                <img src="./assets/image/NewHome/chart.png" alt="" className="w-100 h-100" />
              </div>
            </div>
          </div>
          </div>
      </div>
      <div className="container-fluid WonderSection py-md-4 py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="row mx-0 g-3">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-icon"><img className="w-100 h-100" src="./assets/image/NewHome/cardIcon1.png" alt="" /></div>
                        <div className="card-heading">Freedom</div>
                        <p>Connect your wallet and go and take control of your trading without giving up control of your funds.</p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <div className="card-icon"><img className="w-100 h-100" src="./assets/image/NewHome/cardIcon2.png" alt="" /></div>
                        <div className="card-heading">Decentralization </div>
                        <p>True decentralization means community operated validators, security, and decentralized decision power.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card mt-md-5">
                      <div className="card-body">
                        <div className="card-icon"><img className="w-100 h-100" src="./assets/image/NewHome/cardIcon3.png" alt="" /></div>
                        <div className="card-heading">High Yield </div>
                        <p>Get high yield on your staking through our proven business model.</p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <div className="card-icon"><img className="w-100 h-100" src="./assets/image/NewHome/cardIcon4.png" alt="" /></div>
                        <div className="card-heading">Fairness</div>
                        <p>The Big BetPlatform is owned by its holders. We empower everyone so that their voice can be heard.</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="heading ps-lg-5 mb-2"><span>What is</span>Big Bet?</div>
              <p className="pero ps-lg-5 mb-3">Big Bet is a decenterlize exchange you can swap multiple coins provide liquidity yeild farming and stake make huge profit you get Big Bet open Marketplace on Pancake Swap you can buy and sell Big Bet here DEXs offer several benifits over centralized exchange. Including enhanced security, privacy, control, transparency,and lower fees.</p>
              <a href="/login" className="anker ms-lg-5">Sign up</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid FaqSection py-md-4 py-3" id="faq">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mb-3 mb-3">
              <div className="subHeading">IMPORTANT FAQ</div>
              <div className="Heading">Frequently Asked  <span>Questions</span></div>
            </div>
            <div className="col-md-6">
              <div className="faqimage">
                <img className="w-100 h-100" src="./assets/image/NewHome/faq.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    1. What is Big Bet?
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Big Bet is a cutting-edge digital currency that harnesses the power of blockchain technology, offering users a range of features and opportunities in the world of decentralized finance (DeFi).
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    2. On which blockchain is Big Betbased?
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Big Betis built on the Binance Smart Chain (BEP-20), leveraging its robust infrastructure to provide a secure and efficient environment for token transactions and operations.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    3. What are the benefits of staking Big Bet?
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Staking Big Bet provides several benefits, such as earning passive income through staking rewards, contributing to the stability and security of the network, and having a voice in governance decisions.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    4. How can I maximize my returns through the Big Betproject?
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Big Betoffers a range of avenues to reap profits and enjoy the associated benefits. By participating in our active referral program, you have the opportunity to earn rewards by introducing new users to the Big Betecosystem.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    5. Has Big Betbeen listed on any exchanges?
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Currently, Big Betcan be acquired directly through our official website, where it can be securely linked to Trust Wallet for storage and management
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid informationsectiob py-md-4 py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="subheaing">Big Bet INFORMATION</div>
              <div className="heading mb-3">Big Bet<span>Gaming</span></div>
            </div>
            <div className="col-md-6">
                <div className="suHeading">Big Bet Gaming Platform - <span>launching Soon</span> </div>
               <p className="text-white">Big Bet is an online crypto casino and gaming platform that allows players to enjoy a wide range of games using various cryptocurrencies such as Bitcoin, Ethereum, and more. The platform offers a diverse selection of games including slots, table games, live dealer games, and sports betting. Big Bet is known for its provably fair gaming system, which ensures that all games are fair and transparent</p>
            </div>
            <div className="col-md-6">
              <div className="informationimage">
                <img className="w-100 h-100" src="./assets/image/NewHome/token.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid informationsectiob py-md-4 py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="informationimage">
                <img className="w-100 h-100" src="./assets/image/NewHome/token2.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
                <div className="suHeading">Big Bet Dapp- <span>Wallet</span> </div>
                <p className="text-white">Big Bet dapp wallet is user friendly interface Dapps have their backend code (smart contracts) running on a decentralized network and not a centralized server. They use the Ethereum blockchain for data storage and smart contracts for their app logic. A smart contract is like a set of rules that live on-chain for all to see and run exactly according to those rules. Imagine a vending machine: if you supply it with enough funds and the right selection, you'll get the item you want. And like vending machines, smart contracts can hold funds much like your Ethereum account. This allows code to mediate agreements and transactions.
                Once dapps are deployed on the Ethereum network you can't change them. Dapps can be decentralized because they are controlled by the logic written into the contract, not an individual or a company</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid contactUssection py-md-4 py-3" id="contact">
        <div className="container">
          <div className="row align-items-center g-3">
            <div className="col-12">
              <div className="subheading">Get In Touch</div>
              <div className="Heading">Contact <span>Us</span></div>
            </div>
            <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="card-heading">Request a contact us</div>
                    <p>Revail The universal acceptance of building has given a tremendous opportunity for merchants to do crossborder transactions instantly and at reduced cost.</p>
                    <a href="mailto:@infoBigbet.in" className="emailanker"> <span></span>@infoBigbet.in</a>
                    <div className="socilaiconheading">Big Bet Social Media Link</div>
                    <ul className="socialicon">
                      <li><a className="anker" href=""><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li><a className="anker" href=""><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                      <li><a className="anker" href="https://t.me/+eK1k5qZb3ThiYjk0"><i className="fa fa-telegram" aria-hidden="true"></i></a></li>
                      <li><a className="anker" href=""><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li><a className="anker" href=""><i className="fa fa-youtube" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                </div>
            </div>
            <div className="col-md-6">
              <form className="row mx-0 formsec" action="">
                  <div className="col-sm-6">
                    <div className="form-floating mb-3">
                        <input type="text" class="form-control shadow-none" id="name" placeholder="Name" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating mb-3">
                        <input type="email" class="form-control shadow-none" id="Email" placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                        <input type="text" class="form-control shadow-none" id="Subject" placeholder="Subject" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div class="form-floating">
                      <textarea class="form-control shadow-none" placeholder="Massage" id="floatingTextarea2" style={{height:"150px"}} ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="btn submitbtn">Submit</div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </>
      <Footer />
    </div>
  );
};

export default Hero;
