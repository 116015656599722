import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import { updateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { API } from "../../Utils/API";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Withdrawal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);

  let [Amount, setAmount] = useState("");
  const [token, setToken] = useState(0);
  let [spiner, setspiner] = useState(false);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [loading, setloading] = useState(false);
  const [TopupStatus, setTopupStatus] = useState("0");
  let [USDT, setUSDT] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);

      setWallet_Address(responce.btcaddress);
      setTopupStatus(responce.top_up);
      setUSDT(responce.Net_Balance);
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const CalculateValue = async (val) => {
    const Selvalues = DOMPurify.sanitize(
      val.replace(/[^0-9]/gi, "").substring(0, 6)
    );
    calculateToken(Selvalues);
    setAmount(Selvalues);
  };

  const calculateToken = async (value) => {
    try {
      let responce = await API?.get(
        `getTokenValue?usdvalue=${value || 0}&type=Buy`,
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("resp", responce?.data?.data[0][0]?.Token);
      setToken(responce?.data?.data[0][0]?.Token);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const WithdrawalUser = async (tstatus) => {
    try {
      if (user === undefined || user === "" || user === null) {
        navigate("/");
      }
      let acc =await loadWeb3();
      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        return;
      } else if (acc == "Wrong Network") {
        //toast.error("Wrong Network Please Connect BSC Scan Network");
        console.log("Wrong Network Please Connect Polygon Scan Network");
      } else {
        // if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if(1>0){
          if (Amount !== 0) {
            if (parseFloat(USDT) < parseFloat(Amount)) {
              toast.error("Insufficient USDT Balance");
              setspiner(false);
              return;
            } else {
              setspiner(true);
            }

            if (parseFloat(Amount) < parseFloat(10)) {
              toast.error("You can wihdrawal minimum 10 USDT");
              setspiner(false);
              return;
            } else {
              setspiner(true);
            }

            let res = await API.post(
              "/withdrawal",
              {
                uid: user,
                usd: Amount,
                token: Amount,
                bnb: Amount,
              },
              {
                headers: {
                  Authorization: `${jwt}`,
                  "Content-Type": "application/json",
                },
              }
            );

            // console.log("res-->", res);
            if (res.data.data === "SUCCESSFUL") {
              toast.success("Withdrawal Successful !!!");
              GetUserDetails();
              setAmount(0);
              setToken(0);
              setspiner(false);
            }
            else{
              toast.error(res.data.data);
              setspiner(false);
            }

            setspiner(false);
          }
        } else {
          toast.error("Invalid Wallet address");
        }
      }
    } catch (err) {
      // console.log("error while calling fuction sell", err);
      if (
        err.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
      setspiner(false);
    }
  };

  useEffect(() => {
    WalletConnected();

    GetUserDetails();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Withdrawal</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Withdrawal</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Withdrawal
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100 flex-wrap ">
                    <h4 className="w-100 text-center pt-2">
                      Wallet is Connected
                    </h4>
                    <p className="text-white fs-16 text-center w-100">
                      Available Net Balance : $ {USDT}
                    </p>
                  </div>
                  <div className="modal-body">
                    <form
                      className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                    >
                      <div className="panel-body">
                      <div className="row align-items-center justify-content-center">
                          <div className="col-md-4 col-6">
                            <label>Wallet Address </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              readOnly
                              value={Wallet_Address}
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center justify-content-center">
                          <div className="col-md-4 col-6">
                            <label>Enter USDT </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput"
                              min={1}
                              step={1}
                              maxLength={4}
                              value={Amount}
                              onChange={(e) => CalculateValue(e.target.value)}
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div
                          className="row align-items-center justify-content-center"
                        >
                          <div className="col-md-4 col-6">
                            <label>Fees </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput1"
                              value={Amount*0.1}
                              readOnly
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div
                          className="row align-items-center justify-content-center"
                        >
                          <div className="col-md-4 col-6">
                            <label>Game Fees </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput1"
                              value={`${Amount * 0.05} (${parseFloat(Amount * 0.05) * parseFloat(90)
                                })`}
                              readOnly
                              // value={Amount * 0.05}
                              // readOnly
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div
                          className="row align-items-center justify-content-center"
                        >
                          <div className="col-md-4 col-6">
                            <label>Net Payable </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput1"
                              value={Amount - Amount * 0.15}
                              readOnly
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        {/* <div
                          className="row align-items-center justify-content-center"
                        >
                          <div className="col-md-4 col-6">
                            <label>Net Payable</label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="amountInput1"
                              value={((Amount - Amount * 0.15) * 90)}
                              readOnly
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div> */}
                        <div className="row align-items-center justify-content-center mx-0 pt-3">
                          <div className="col-6 row align-items-center justify-content-center">
                       {parseInt(TopupStatus) === 1 && (
                              <button
                                className="btn btn-theme1 withdraw_btn w-100 py-3 shadow-none"
                                type="button"
                                onClick={() => {
                                  WithdrawalUser();
                                }}
                              >
                                Withdrawal
                              </button>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: You can withdrawal minimum $10.
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Withdrawal;
