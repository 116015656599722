import React, { useEffect, useState } from "react";
import "./Add_Fund.css";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { updateAuth } from "../../Redux/AuthSlice";

import {
  Abi_Main,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_USDT,
} from "../../Utils/ContractAddress";
import { Spinner } from "react-bootstrap";

const ActivateByFund = () => {
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);

  const [IncomeFundBal, setIncomeFundBal] = useState(0);
  const [usdtAmount80, setusdtAmount80] = useState(0);
  const [incomeFund20, setincomeFund20] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userTopup, setuserTopup] = useState(0);
  const [userCPack, setuserCPack] = useState("");
  const [user_Id, setuser_Id] = useState("");
  const [user_IdValid, setuser_IdValid] = useState("");
  const [checkvalid, setcheckvalid] = useState(false);
  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const CalculateValue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(Amount);
    setAmount(Totalvalue);
    setusdtAmount80(0.8 * Totalvalue);
    setincomeFund20(0.2 * Totalvalue);
  };
  const CalculateValueReset = () => {
    setAmount(0);
    setusdtAmount80(0);
    setincomeFund20(0);
    setcheckvalid(false);
    setuserCPack(0);
    setuserTopup(0);
    setuser_IdValid("");
  };
  

  const CheckUserId = async (val) => {
    try {
      setuser_Id(val);
      // let username1 = val + " (Wrong Id)";
      setuser_IdValid(val);
      if (val.length >= 6) {
        let responce = await API?.get(`Check_userId?uid=${val}`, {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        });
        responce = responce.data.data[0][0];
        console.log("responce-->", responce);
        if (responce.userid === val) {
          setcheckvalid(true);
          setuserCPack(responce.CurrentPackage);
          setuserTopup(responce.top_up);
          let username1 = responce.userid + " (" + responce.btcaddress + ")";
          setuser_IdValid(username1);
        } else {
          setcheckvalid(false);
          setuserCPack("0");
          setuserTopup("0");
          let username = "Invalid Id";
          setuser_IdValid(username);
        }
      }
    } catch (e) {
      console.log("userDetails error", e);
    }
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });
      responce = responce.data.data[0][0];
      console.log("getUserDetails", responce);

      setIncomeFundBal(responce.Net_ActivationFund);
      setWallet_Address(responce.btcaddress);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setUSDT(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Activate = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (usdtAmount80 !== 0) {
          if (checkvalid === false) {
            toast.error("Enter Valid UserId to Activate!!!");
            setloading(false);
            return;
          }
          if (USDT < usdtAmount80) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
            return;
          }
          if (IncomeFundBal < incomeFund20) {
            toast.error("Insufficient FUND Balance");
            setloading(false);
            return;
          }

          let amountValue = window.web3.utils.toWei(
            usdtAmount80.toString(),
            "ether"
          );

          const gasPrice = await window.web3.eth.getGasPrice();
          const approveBlock = window.web3.eth.getBlock("latest");

          try {
            let contract_Main = await new window.web3.eth.Contract(
              Abi_Main,
              ContractAddress_Main
            );
            let contract_USDT = await new window.web3.eth.Contract(
              Abi_USDT,
              ContractAddress_USDT
            );

            let approveCall = await contract_USDT.methods
              .approve(ContractAddress_Main, amountValue)
              .send({
                from: acc,
                gasLimit: approveBlock.gasLimit,
                gasPrice: await window.web3.eth.getGasPrice(),
              });
            toast.success("Approved Succesful");
            let sellCall = await contract_Main.methods
              .deposit(amountValue)
              .send({
                from: acc,
                gasLimit: approveBlock.gasLimit,
                gasPrice: await window.web3.eth.getGasPrice(),
              });
            let res = await API.post(
              "activationFUND",
              {
                uid: user_Id,
                referby: user,
                walletAddress: acc,
                usd: Amount,
                token: usdtAmount80,
                bnb: incomeFund20,
                txn: sellCall.transactionHash,
              },
              {
                headers: {
                  Authorization: `${jwt}`,
                  "Content-Type": "application/json", // Adjust the content type as needed
                },
              }
            );
            console.log("activation", res.data);
            toast.success("Transaction Succesful");
            if (res.data.success == true) {
              toast.success("Activation is under process");
              navigate("/dashboard");
            }
          } catch (err) {
            console.log("error while calling fuction sell", err);
            if (
              e.response.data.showableMessage ==
              "Please login to access this resource"
            ) {
              dispatch(
                updateAuth({
                  isAuth: false,
                  userId: null,
                  jwtToken: null,
                  ipAddress: null,
                })
              );
              navigate("/");
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  const Upgrade = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (checkvalid === false) {
            toast.error("Enter Valid UserId to Activate!!!");
            setloading(false);
            return;
          }
          if (USDT < usdtAmount80) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
            return;
          }
          if (IncomeFundBal < incomeFund20) {
            toast.error("Insufficient FUND Balance");
            setloading(false);
            return;
          }
          if (userCPack < Amount) {
            toast.error("You cannot upgrade with lower package");
            setloading(false);
            return;
          }

          let amountValue = window.web3.utils.toWei(
            usdtAmount80.toString(),
            "ether"
          );

          const gasPrice = await window.web3.eth.getGasPrice();
          const approveBlock = window.web3.eth.getBlock("latest");

          try {
            let contract_Main = await new window.web3.eth.Contract(
              Abi_Main,
              ContractAddress_Main
            );
            let contract_USDT = await new window.web3.eth.Contract(
              Abi_USDT,
              ContractAddress_USDT
            );

            let approveCall = await contract_USDT.methods
              .approve(ContractAddress_Main, amountValue)
              .send({
                from: acc,
                gasLimit: approveBlock.gasLimit,
                gasPrice: await window.web3.eth.getGasPrice(),
              });
            toast.success("Approved Succesful");
            let sellCall = await contract_Main.methods
              .deposit(amountValue)
              .send({
                from: acc,
                gasLimit: approveBlock.gasLimit,
                gasPrice: await window.web3.eth.getGasPrice(),
              });
            let res = await API.post(
              "upgradationFund",
              {
                uid: user_Id,
                referby: user,
                walletAddress: acc,
                usd: Amount,
                token: usdtAmount80,
                bnb: incomeFund20,
                txn: sellCall.transactionHash,
              },
              {
                headers: {
                  Authorization: `${jwt}`,
                  "Content-Type": "application/json", // Adjust the content type as needed
                },
              }
            );
            console.log("Upgrade", res.data);
            toast.success("Transaction Succesful");
            if (res.data.success == true) {
              toast.success("Upgrade is under process");
              navigate("/dashboard");
            }
          } catch (err) {
            console.log("error while calling fuction sell", err);
            if (
              e.response.data.showableMessage ==
              "Please login to access this resource"
            ) {
              dispatch(
                updateAuth({
                  isAuth: false,
                  userId: null,
                  jwtToken: null,
                  ipAddress: null,
                })
              );
              navigate("/");
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    USDTBalance();
    GetUserDetails();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Activate/Upgrade By EBT Assets
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Activate/Upgrade</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Activate/Upgrade By EBT Assets
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h6 className="w-100  pt-2 ms-3">
                      Available USDT : {USDT} USDT
                    </h6>
                  </div>

                  <h6 className="w-100  pt-2 ms-3">
                    Available EBT Assets : {IncomeFundBal} USDT
                  </h6>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>User Id</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="user_Id"
                              value={user_IdValid}
                              onChange={(e) => CheckUserId(e.target.value)}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Enter USDT Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={Amount}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between mt-2">
                          <div className="col-sm-4 col-6">
                            <label>USDT Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={usdtAmount80}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between mt-2">
                          <div className="col-sm-4 col-6">
                            <label>EBT Assets</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={incomeFund20}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div
                            className="col-12 mt-3 mb-3 text-center"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                              gap: "10px",
                            }}
                          >
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(50)}
                            >
                              $ 50
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(100)}
                            >
                              $ 100
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(500)}
                            >
                              $ 500
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(1000)}
                            >
                              $ 1000
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 restart"
                              onClick={() => CalculateValueReset()}
                            >
                              Reset
                            </div>
                          </div>
                          {/* <div className="col-12 text-center py-3">
                            <button className="btn btn-Green py-2 px-5 restart"
                             onClick={() => setAmount(0)}>
                              Reset
                            </button>
                          </div> */}
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            {loading == true ? (
                              <>
                                <Loader />
                              </>
                            ) : (
                              <>
                                {parseFloat(userTopup) > 0 ? (
                                  <button
                                    className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                                    onClick={() => Upgrade()}
                                  >
                                    Upgrade
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                                    onClick={() => Activate()}
                                  >
                                    Activate
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Activate/Upgrade Amount Should Be Multiple
                              of $50.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default ActivateByFund;
