import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="xl"
        className="bge bgNav nav-underline"
       
      >
        <Container>
          <Navbar.Brand className="logoHeader" href="/" >
            {" "}
            <img  className="w-100 h-100" src="./assets/image/logo/logo.png"alt="logo"  />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="toglebtn" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto mainbtn1">
              <Nav.Link className="lenk text-white" href="/">
               Home
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#about">
                About Us
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#trade">
              Why Trade 
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#coin">
                Coin Market 
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#faq">
                Faq
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#contact">
                Contact Us
              </Nav.Link>
              <Link
                className="btn mainbtn px-lg-4 py-lg-2 text-white mb-2 mb-lg-0"
                to="/login"
              >
                Sign In
              </Link>
              <Link
                className="btn mainbtn px-lg-4 py-lg-2 text-white ms-md-2"
                to="/Register"
              >
                Sign Up
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
