import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { updateAuth } from "../../Redux/AuthSlice";

const ActivationByFundReport = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const FundHistory_API = async () => {
    try {
      let responce = await API.post(
        "activationHistory",
        {
          uid: user,
          fdate: "",
          tdate: "",
          type: 5,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      // console.log("res-->",responce)
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setreferralApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          Amount: `$ ${item.Amount}`,
          dd: item.dd,
          txnid: item.pinnumber,
          remark: item.remark,
          referby: item.referby,
          Amount80: `$ ${item.tokenvalue}`,
          Amount20: `$ ${item.BNBvalue}`,          	
          transactionHash: (
            <a
              href={`https://bscscan.com/tx/${item.pinnumber}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{color:"white"}}
            >
              View Txn
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      // console.log("Something Error", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    FundHistory_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Activate By", accessor: "referby" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Contract Amount", accessor: "Amount80" },
      { Header: "Fund Amount", accessor: "Amount20" },
      { Header: "Remark", accessor: "remark" },
      { Header: "Txn", accessor: "transactionHash" },
      { Header: "Date", accessor: "dd" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  {" "}
                  Activate / Upgrade By EBT Assets History
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">
                    {" "}
                    Activate / Upgrade 
                  </div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Activate / Upgrade By EBT Assets History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default ActivationByFundReport;
