import React,{useEffect,useState} from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import { updateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Profileupdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);

  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Mobilenew, setMobilenew] = useState("");
  const [Email, setEmail] = useState("");
  let [spiner, setspiner] = useState(false);
  const [getData, setgetData] = useState([]);


  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`getUserDetail_byuserid?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);

      setName(responce.f_name);
      setEmail(responce.email);
      setMobile(responce.mobile);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const handleGet = (event) => {
    // const newValue = event.target.value.replace(/[^0-9]/gi, "");
    // setMobile(newValue);
    const { name, value } = event.target;
    setgetData({ ...getData, [name]: value });
  };

  const updateprofile_post_Api = async (tstatus) => {
    try {
      
      let res = await API.post(
        "/UpdateProfile",
        {
          uid: user,
          username: getData?.Name || Name,
          mobile: getData?.Mobile || Mobile,
          email: getData?.Email || Email,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("res-->", res);
      if (res.data.data === "SUCCESSFUL") {
        toast.success("Profile Update Successful !!!");
        GetUserDetails();
      }
      else{
        toast.error(res.data.data);
        setspiner(false);
      }

    } catch (err) {
       console.log("error while calling fuction sell", err);
      
    }
  };


  useEffect(() => {
    GetUserDetails();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Update Profile</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Update Profile</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Update Profile
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                 
                  <div className="modal-body">
                    <form
                      className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                    >
                      <div className="panel-body">
                      <div className="row align-items-center justify-content-center">
                          <div className="col-md-4 col-6">
                            <label>Name </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              Name="Name"
                              maxLength={20}
                              className="form-control mb-20  shadow-none"
                              defaultValue={Name}
                              style={{ width: "90%" }}
                              onChange={handleGet}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center justify-content-center">
                          <div className="col-md-4 col-6">
                            <label> Email</label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20  shadow-none"
                              id="Email"
                              name="Email"
                              maxLength={100}
                              defaultValue={Email}
                              style={{ width: "90%" }}
                              onChange={handleGet}
                            />
                          </div>
                        </div>
                        <div
                          className="row align-items-center justify-content-center"
                        >
                          <div className="col-md-4 col-6">
                            <label>Mobile </label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              maxLength={10}
                               ng-model="p.Mobile"
                               name="Mobile"
                              className="form-control mb-20"
                              id="Mobile"
                              defaultValue={Mobile}
                            //value={(e)=>setMobilenew(e.target.values)}
                              //  value={Mobilenew}
                              style={{ width: "90%" }}
                              onChange={handleGet}
                            />
                          </div>
                        </div>
                        <div
                          className="row align-items-center justify-content-center"
                        >
                          
                        </div>
                        <div className="row align-items-center justify-content-center mx-0 pt-3">
                          <div className="col-6 row align-items-center justify-content-center">
                              <button
                                className="btn btn-theme1 withdraw_btn w-100 py-3 shadow-none"
                                type="button"
                                onClick={() => {
                                  updateprofile_post_Api();
                                }}
                              >
                                Update
                              </button>
                          </div>
                         
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Profileupdate;
